<template>
  <div class="login-container">
    <div class="page">
      <div class="login">
        <el-form :model="form" status-icon :rules="rules" ref="loginForm" label-width="60px" size="small">
          <el-form-item>
            <span class="title">欢迎进入资本数字空间</span>
          </el-form-item>
          <el-form-item>
            <span class="ctitle">运营平台</span>
          </el-form-item>
          <el-form-item prop="username">
            <el-input v-model.number="form.username" placeholder="请输入你的登录账号"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="form.password" autocomplete="off" placeholder="请输入你的登录密码" @keyup.enter="login"></el-input>
            <!-- <el-icon :size="22" color="#fff" style="position: absolute;top: 50%;transform: translateY(-50%);color: #fff;right: 10px;">
              <Open />
            </el-icon> -->
          </el-form-item>
          <el-form-item>
            <div class="login-btn" @click="login">登录</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, ref } from 'vue'
import { passwordEncrypte } from '../utils/format';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入你的登录账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入你的登录密码', trigger: 'blur' }]
      }
    })
    const loginForm = ref()

    const login = () => {
      const newPwd = passwordEncrypte(proxy, proxy.$md5(state.form.password))
      let data = {
        account: state.form.username,
        authCode: "",
        password: newPwd,
        phone: "",
        type: ""
      }
      loginForm.value.validate(valid => {
        if (valid) {
          proxy.$post('/cts/back/backUser/login', data).then((res) => {
            if (res.code === '200') {
              const { data } = res;
              const { user } = data;
              localStorage.setItem("user_token", data.token);
              localStorage.setItem("userName", user.userName);
              localStorage.setItem("roleId", user.roleId);
              localStorage.setItem("menus", JSON.stringify(data.menus));
              router.push({ path: '/homePage' })
            }
          }).catch((err) => {

          });
        }
      })
    }

    return {
      ...toRefs(state),
      login,
      loginForm
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  background-image: url("../assets/login_bg.png");
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.page {
  width: 500px;
  height: 400px;
  .login {
    position: absolute;
    top: 50%;
    transform: translateY(-70%);
    /deep/.el-input__wrapper {
      box-shadow: 0 0 0 0;
      background: none;
    }
    .title {
      color: #fff;
      font-size: 40px;
    }
    .ctitle {
      color: #fff;
      font-size: 32px;
    }
  }
  .login-btn {
    width: 190px;
    height: 50px;
    border-radius: 25px;
    background-color: #2d97b2;
    border: none;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>